import React from "react"
import { useRef } from "react";
import { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import { useInView } from "framer-motion";
import "./Home.min.scss";
// import { NavLink as RouterLink, useLocation, useNavigate } from "react-router-dom";
// import { Link as ScrollLink } from "react-scroll";
// import * as Scroll from "react-scroll";
// import { animateScroll as scroll } from "react-scroll";
// import Hero from '../Hero/Hero';
import "./animate.scss";

import { Link } from 'react-scroll';

const Home: React.FC = () => {
  const pix: HTMLCollectionOf<Element> = document.getElementsByClassName("pixel");

  for (let i = 0; i < pix.length; i++) {
    (pix[i] as HTMLElement).style.animationDelay = Math.ceil(Math.random()*1000) + "ms";
  }
  
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  


  return (
    <>
      <section id="homepage" className="bg-primary lg:bg-center">
        <div className="hero-page"></div>
        <div className="c-page-header o-container c-page-header--big">
          <h1 className="c-page-header__title o-heading-split o-text-reveal is-initialized">
            <div className="o-text-reveal__wrapper">
              <div className="o-text-reveal__item">
                <motion.span style={{ display: "inline-block" }}>
                  Jamie
                </motion.span>
              </div>
            </div>
            <div className="o-text-reveal__wrapper">
              <div className="o-text-reveal__item">
                  <span className="right green">
                      Full stack Developer
                  </span>
              </div>
            </div>
            <div className="o-text-reveal__wrapper">
              <div className="o-text-reveal__item">
                <span className="left">
                    Fudge
                </span>
              </div>
            </div>
          </h1>
        </div>
<br></br>

        <div className="intro o-container mb-5">
        
           <p className="white">
             
             <span className="intro-text">I specialize in <span className="highlight">translating complex ideas into user-friendly interfaces</span> that captivate and engage.
             </span>
          </p>

          
        <Link to="contact" smooth={true} duration={500}>
                <motion.button style={{
                  // transform: isInView ? "none" : "translateX(29px)",
                  // opacity: isInView ? 1 : 1,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                }} className="btn-main"
                  transition={{ type: "spring", stiffness: 400, damping: 10, duration: 0.5, delay: 0.4 }}>
                  Contact Me!
                </motion.button>
              </Link>
        </div>

        
        <div className="cursor">
          <div className="cursor__inner"></div>
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
              <filter id="gooey">
                <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="3.2" />
                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -7" result="goo" />
                <feComposite in="SourceGraphic" in2="goo" operator="atop" />
              </filter>
            </defs>
          </svg>
        </div>

      </section>

    </>
    
  );
}


export default Home