import React from "react"
import "./Portfolio.scss";

import { Reveal } from "../utils/Reveal";

import {FiExternalLink} from "react-icons/fi";
import {FaGithub} from "react-icons/fa";

import { useAnimation, useInView, motion } from "framer-motion";

import { useEffect, useRef, useState } from "react";


interface Props {
  tech: string[];
  description: string;
  projectLink: string;
  imgSrc: string;
  title: string;
  code: string;
}

const Project: React.FC<Props> = ({
  projectLink,
  description,
  imgSrc,
  title,
  code,
  tech,
}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [hovered, setHovered] = useState(false);
  
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isOpen, setIsOpen] = useState(false);
  
    const controls = useAnimation();
  
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
  
    useEffect(() => {
      if (isInView) {
        controls.start("visible");
      } else {
        controls.start("hidden");
      }
    }, [isInView, controls]);

  return (
      <motion.div
        ref={ref}
        variants={{
          hidden: { opacity: 0, y: 100 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={controls}
        transition={{ duration: 0.75 }}>
        <div onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={() => setIsOpen(true)}>
        </div>

        <a href={code}><img className="projectImage" src={imgSrc} alt="project" /></a>
        
        <div className="projectCopy">
          <Reveal>
            <div className="head">
              <h4 className="aqua folio-head">{title}</h4>
              {/* <div className="projectTitleLine" /> */}
              
            </div>
          </Reveal>
         
        <Reveal>
          <p className="white folio-text folio-text">
            {description}{" "}
            <br />
          </p>
        </Reveal>
        <Reveal>
          <div>
            <ul className="green portfolio-list">
              {tech.map((tech, index) => (
                <li className="user" key={index}>{tech}</li>
              ))}
            </ul>
          </div>
        </Reveal>
        <Reveal>
          <div>
            {projectLink ? (
              <a className="portfolio-link d-inline-block pt-2 pr-4" href={projectLink}>
                <FiExternalLink className="green w-6 h-6" />
              </a>
            ) : null}
            {code ? (
              <a className="portfolio-link d-inline-block pt-2" href={code}>
                <FaGithub className="green w-6 h-6" />
              </a>
            ) : null}
          </div>
        </Reveal>
        </div>
      </motion.div>
  );
};

export default Project;

