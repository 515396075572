import React from "react";
import Socials from "../Socials/Socials"
import "./Footer.scss";

const Footer: React.FC = () => {
    return (
        <footer id="footer" className="py-12 bg-tertiary">
            <div className="container mx-auto">
                <div className="flex flex-col items-center justify-between space-y-6 lg:flex-row lg:space-y-0">
                    <div className="flex items-center justify-center space-x-6">
                        <Socials />
                    </div>
                    <div className="logo-sm">
                        
                    </div>
                    <p className="subtitle copy drac-text">&copy; Designed & Built by Jamie Fudge</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer

