import React, { useEffect } from "react"
import { useState } from "react"
// import Logo from "../"
import Nav from "../Nav/Nav";
import Socials from "../Socials/Socials";
import NavMobile from "../NavMobile/NavMobile";
import "./Header.scss";

const Header: React.FC = () => {
    const [bg,setBg] = useState(false)

    useEffect(()=> {
        window.addEventListener('scroll', () => {
            return window.scrollY > 572 ? setBg(true) : setBg(false)
        })
    })
  return (
    <header id="header" className={`${bg ? 'setBg h-20' : 'h-24'} flex items-center fixed top-0 w-full z-10 transition-all duration-300`}>
        <div className="container mx-auto h-full flex items-center justify-between">
            <a href="/">
                {/* <img src={Logo} alt="Logo" /> */}
            </a>

            <div className='hidden lg:block'>
                <Nav />
            </div>
            <div className='hidden lg:block'>
                <Socials />
            </div>
            <div className='lg:hidden'>
                <NavMobile />
            </div>
        </div>
    </header>
  )
}

export default Header