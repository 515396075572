import * as React from "react";
import {navigation} from '../../data'
import { NavLink as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {Link, } from "react-scroll";
import * as Scroll from "react-scroll";
// import { motion } from "framer-motion";

const NavBar: React.FC = () => {
  // const path = useLocation().pathname;
  // const location = path.split("/")[1];
  // const navigate = useNavigate();
  // const scroller = Scroll.scroller;

  return (
   <nav>
      <ul className="flex list-none space-x-8 uppercase text-[15px]">
        {navigation.map((item, index) => {
          return (
            <li className="text-white hover:text-accent cursor-pointer" key={index}>
         
                <Link to={item.href}
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-75}
                  className="transition-all duration-300">
                  {item.name}
                </Link>
              
            </li>
          );
        })}
      </ul>
     
    </nav>  
  );
}

  export default NavBar