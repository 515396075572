interface HeadingProps {
  title: string;
}

const Heading: React.FC<HeadingProps> = ({ title }) => {
  return (
    <>
      <div className="section-heading select-none">
        <div className="heading flex translate-y-56">
          <h3 className="folio-head white stroke w-fit text-5xl sm:text-heading-2 font-medium uppercase text-secondary-600">
          {title}
          </h3>
        </div>
        <div className="heading flex translate-y-56">
          <h3 className="folio-head white mb-5 w-fit text-5xl sm:text-heading-2 font-medium uppercase text-secondary-600">
            <span className="white">Projects</span>
          </h3>
        </div>
      </div>
      </>
  );
};

export default Heading;