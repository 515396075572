import { motion } from 'framer-motion';

// import styles from '../styles';
import { fadeIn } from '../utils/motion';

const ServiceCard = ({ id, title, text, icon,
  index, active, handleClick }) => (
  <motion.div
    variants={fadeIn('right', 'spring', index * 0.5, 0.75)}
    className="flex items-center justify-center transition-[flex] duration-[0.7s] ease-out-flex 
    drac-box drac-card drac-card-subtle drac-border-pink drac-rounded-lg drac-p-sm service"
    onClick={() => handleClick(id)}>
      <div className="flex justify-start w-full flex-col rounded-b-[24px]">
        <div className={`glassmorphism`}>
        </div>
        <div>{icon}</div>
        <h4 className="green s-head service-heading">
          {title}
        </h4>
        <p className="white s-text service-text">
          {text}
        </p>
      </div>
  </motion.div>
);

export default ServiceCard;