import React from "react";
// import { icons } from 'react-icons';
import { GiBleedingEye }from "react-icons/gi";
import "./Contact.scss";
import { motion } from "framer-motion";
import { useState } from 'react';  
import useMousePosition from "../utils/useMousePosition";

const Contact: React.FC = () => {

  const [isHovered, setIsHovered] = useState(false);

  const size = isHovered ? 400 : 40;
  const { x, y } = useMousePosition();
  return (
    <section className="section bg-primary min-h-[500px]" id="contact">

      <div className="main">
      <h4 className="white contact-me">Contact</h4>
        <span className="container center"><GiBleedingEye className="contact-eye text-center mb-3" /></span>
        
        <><motion.div
          className="mask"
          animate={{
            WebkitMaskPosition: `${x ? x - (size / 2) : 0}px ${y ? y - (size / 2) : 0}px`, // Check if x and y are not null
            WebkitMaskSize: `${size}px`,
          }}
          transition={{ type: "tween", ease: "backOut", duration: 0.5 }}>

          <p onMouseEnter={() => { setIsHovered(true) }} onMouseLeave={() => { setIsHovered(false) }}>
            Have an idea to discuss? Shoot me an email if you want to connect!
            My inbox is always open. Whether you have a question or just want to say hi,
            send me a message and let's chat!
          </p>
        </motion.div> </>
        
        <div className="body">
          
          <p>Have an idea to discuss? Shoot me an email if you want to connect!
            My inbox is always open. Whether you have a question or just want to say hi,
            send me a message and let's chat!</p>
        </div>

        <div className="container">
          <a href="mailto:jamiefudge13@gmail.com">
            <button className="btn btn-lg btn-contact drac-text mx-auto">
              Send Message
            </button>
          </a>
        </div>

        <div className="mx-auto">
          <a href="mailto:jamiefudge13@gmail.com"><button className="text-center send-button d-sm-block d-md-none d-lg-none">
            <span className="drac-text">Send Message</span>
          </button></a>
        </div>

      </div>

    </section>
  )
};

export default Contact;