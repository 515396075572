import React from "react";
import {Tilt} from "react-tilt";
import { motion } from "framer-motion";
import {GiRaven,GiBirdClaw} from "react-icons/gi";
import "./Experience.scss";

const Experience: React.FC = () => {
    return (
      <section id="experience" className="section bg-primary">
        <div className="container mx-auto">
          <div className="flex flex-col text-left">
            <h1 className="white e-text email">ONLY ONE EMAIL</h1>
            <h1 className="white e-text email">AWAY...</h1>
          </div>
          <br></br>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 align-items-stretch">
              <motion.div>
                <Tilt
                  options={{
                    // max: 35,
                    scale: 1,
                    speed: 450,
                  }}
                  className="">
                  <div className="drac-box drac-card drac-card-subtle bg-green drac-rounded-lg drac-p-sm border-0 h-100">
                    <h4 className="green s-head head b">Front-End Development</h4>
                    <ul className="list">
                      <li className="black s-text ex-content-text">HTML5 / CSS / SASS / PHP</li>
                      <li className="black s-text ex-content-text">
                        Bootstrap / Tailwind / Foundation / Material
                      </li>
                      <li className="black s-text ex-content-text">JavaScript / JQuery / Django / Python</li>
                      <li className="black s-text ex-content-text">ReactJS / Next.js / Angular/ Typescript</li>
                      <li className="black s-text ex-content-text">ASP.NET </li>
                      <li className="black s-text ex-content-text">CRM / CMS Development</li>
                      <br></br>
                      <br></br>
                      <br></br>
                    </ul>

                    <GiBirdClaw className="ex-text text-right" />
                  </div>
                </Tilt>
              </motion.div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 align-items-stretch">
              <motion.div>
                <Tilt
                  options={{
                    // max: 35,
                    scale: 1,
                    speed: 450,
                  }}
                  className="">
                  <div className="drac-box drac-card drac-card-subtle bg-aqua drac-rounded-lg drac-p-sm border-0 h-100">
                    <h4 className="green s-head head b d-inline-block">
                      Backend Development
                    </h4>
                    <ul className="list">
                      <li className="black s-text ex-content-text">Node.js</li>
                      <li className="black s-text ex-content-text">MongoDB</li>
                      <li className="black s-text ex-content-text">Express</li>
                      <li className="black s-text ex-content-text">SQL</li>
                      <li className="black s-text ex-content-text">MySQL</li>
                      <li className="black s-text ex-content-text">WordPress</li>
                      <br></br>
                      <br></br>
                      <br></br>
                    </ul>
                    <GiRaven className="ex-text d-inline-block" />
                  </div>
                </Tilt>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Experience