import React from "react"
// import { Link } from "react-scroll";
import {  Routes } from "react-router-dom";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from "../Home/Home";
import About from "../About/About";
import Portfolio  from "../Portfolio/Portfolio";
import Services from "../Services/Services";
import Contact from "../Contact/Contact";

import NavBar from "../NavBar/NavBar";

const Nav: React.FC = () => {
  return (
    <>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={null} />
        </Routes>
      </Router>
    </>
  );
}



export default Nav