import React from "react";
 import "./Companies.scss";

 const Companies: React.FC = () => {
    return (
        <section id="companies" className="section bg-tertiary">
            <div className="container mx-auto des">
                <div className="row">
                    <div className="col-12">
                        <br></br> <br></br>
                        <div className="drac-box">
                            <div className="drac-box companies company-container">
                                <span className="drac-text company-info drac-line-height drac-text-white">
                                    Companies I've worked with
                                </span>

                                <div id="box">
                                    <div className="drac-box company-wrapper">
                                        <div className="logo-wrapper">
                                            <div className="logo-container">
                                                <img alt="bmo" aria-hidden="true" role="presentation"
                                                    width="155px" height="39px" src="../img/bmo-logo.png" />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="drac-box company-wrapper">
                                        <div className="logo-wrapper">
                                            <div className="logo-container">
                                                <a href="###" target="_blank" rel="noopener">
                                                    <img alt="caat" aria-hidden="true" role="presentation"
                                                        src="../img/caat-logo.png" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="drac-box company-wrapper">
                                        <div className="logo-wrapper">
                                            <div className="logo-container">
                                                <img alt="cibc" aria-hidden="true" role="presentation"
                                                    src="../img/cibc-logo.png" />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="drac-box company-wrapper">
                                        <div className="logo-wrapper">
                                            <div className="logo-container">
                                                <img alt="klick" aria-hidden="true" role="presentation"
                                                    width="49px" height="78px"
                                                    src="../img/klick-logo.png" />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="drac-box company-wrapper">
                                        <div className="logo-wrapper">
                                            <div className="logo-container">
                                                <img alt="" aria-hidden="true" role="presentation"
                                                    src="../img/globemail-logo.png" />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Companies