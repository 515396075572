import React from "react";
import "./About.scss";

const About: React.FC = () => {
    return (
        <section id="about" className="about bg-secondary">
            <div className="wrapper">
                <div className="about-me-section">
                    <div className="content">
                        <div className="avatars">
                            <div className="avatar yellow lazyloaded">
                                <img src="../img/circle1.png" alt="festival event merch printing" width="160" height="160" className="lazyautosizes ls-is-cached lazyloaded ls-inview" />
                            </div>
                            <div className="avatar texas lazyloaded">
                                <img src="../img/circle2.png" alt="custom event bandana printing" width="160" height="160" className="lazyautosizes lazyloaded" />
                            </div>
                            <div className="avatar violet lazyloaded">
                                <img src="../img/circle3.png" alt="custom tote bags printing" width="160" height="160" />
                            </div>
                        </div>
                        <h2 className="heading aqua">
                            ABOUT
                        </h2>
                        <p className="paragraph drac-text drac-text-white">
                            I’m a passionate <span className="green f-500 uppercase">fullstack developer</span> from <span className="pink f-500 uppercase">Toronto</span> <span className="aqua f-500 uppercase">Canada</span>. With a mission to create delightful and intuitive digital experiences. With a strong foundation in design principles and a keen eye for detail,
                             I love creating interactive applications and dynamic user experiences.
                            I'm a developer by day, a music lover 24/7 and a lover of all things outdoors. Let's connect!&#127881; </p>
                    </div>
                </div>
                <div className="text-center">
                    <a href="https://www.linkedin.com/in/jamiefudge/" className="btn btn-lg btn-white">
                        <div className="btn-bg"></div>
                        <div className="btn-text">
                            Connect with me
                            <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.1022 7.75413C18.3339 7.60911 13.8575 6.00383 11.6599 0.533936L9.34011 1.46596C10.5042 4.36335 12.2262 6.38334 14.0036 7.74995H0V10.2499L14.0036 10.2499C12.2262 11.6166 10.5042 13.6365 9.34011 16.5339L11.6599 17.466C13.8575 11.9961 18.3339 10.3908 20.1022 10.2458L20.0512 8.99995L20.1022 7.75413Z" fill="#0F0F0F"></path>
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
        </section>
  );
}
export default About;