import React, { useState } from "react";
import { navigation } from "../../data";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { Bars3BottomRightIcon } from "@heroicons/react/24/outline";

// import { social } from "../../data";

import { Link } from "react-scroll";

import "./NavMobile.scss";

import { motion } from "framer-motion";

const NavMobile: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const circleVariants = {
    hidden: {
      scale: 0,
    },
    visible: {
      scale: 180,
      transition: {
        type: "spring",
        stiffness: 160,
        damping: 60,
      },
    },
  };

  const ulVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.1,
      },
    },
  };

  const variants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      },
    },
    hidden: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  };

  return (
    <nav className="relative">
      <div
        onClick={() => setIsOpen(true)}
        className="cursor-pointer text-white">
        <Bars3BottomRightIcon className="w-8 h-8" />
      </div>

      <motion.div
        variants={circleVariants}
        initial="hidden"
        animate={isOpen ? "visible" : "hidden"}
        className="w-4 h-4 rounded-full bg-accent fixed top-0 right-0">
      </motion.div>

      <motion.ul
        variants={ulVariants}
        initial="hidden"
        animate={isOpen ? "visible" : ""}
        className={`${isOpen ? "left-0" : "right-full"
          } fixed top-0 bottom-0 w-full flex flex-col justify-center items-center transition-all duration-300 overflow-hidden`}>
        <div
          onClick={() => setIsOpen(false)}
          className="cursor-pointer absolute top-8 right-8">
          <XMarkIcon className="w-8 h-8" />
        </div>
        {navigation.map((item, index) => {
          return <motion.li variants={variants} whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }} key={index} className='mb-8'>
            <Link className="text-xl cursor-pointer uppercase font-semibold" to={item.href} smooth={true} duration={500} offset={-70}>
              {item.name}
            </Link>
          </motion.li>
        })}
      </motion.ul>
    </nav>
  );
};

export default NavMobile;
