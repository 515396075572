
import Project from "./Project";
import "./Portfolio.scss";
import React from "react";
import { useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/all";
import { gsap } from "gsap";
import  Heading  from "./Heading";

const Portfolio: React.FC = () => {
  const portfolioSection = useRef(null);
  const heading = useRef(null);
  const body = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: portfolioSection.current,
      start: "top 400px",
      animation: gsap
      .timeline()
      .to(
        heading.current,
        { opacity: 1, y: 0, ease: "power4.out", duration: 1.25 },
        0
      )
      .to(
        body.current,
        { opacity: 1, y: 0, ease: "power4.out", duration: 1.25 },
        0.2
      ),
    toggleActions: "play none none none",
  });
  ScrollTrigger.refresh();
  }, [portfolioSection]);

  return (
   
    <section ref={portfolioSection} id="portfolio" className="section bg-tertiary">
       <><div className="container mx-auto">    
          <Heading title="Recent" />
          <div ref={body} className="projects">
            {portfolio.map((project) => {
              return <Project key={project.title} {...project} />;
            })}
          </div>
      </div></>
    </section>
  );
};

export default Portfolio;

const portfolio = [
  {
    title: "Alien Finder App",
    imgSrc: "/img/portfolio/p8.jpg",
    code: "https://github.com/jfudge/countries-api",
    projectLink: "https://main--alien-app.netlify.app/",
    tech: ["Typescript", "Nextjs", "React", "JavaScript", "Tailwind CSS", "RESTful API"],
    description:
      "This typescript app serves as a platform where ETs can easily locate and access diverse information about humans across different locations. Built with the REST countries API. ",
  },
  {
    title: "CAAT Pension",
    imgSrc: "/img/portfolio/p1.png",
    code: "",
    projectLink: "https://www.caatpension.ca/",
    tech: ["ASP.NET", "HTML5", "CSS", "Bootstrap", "Kendo UI", "JavaScript", "SQL", "C#"],
    description:
      "Developed and implemented new and improved UIs for CAAT Pension Plan. I worked on new features for the multi-language public-facing Member Portal and improved the front end accessibility of all CAAT websites and applications. Back-end code maintained and updated in SQL.",
  },
  {
    title: "BOOK APP",
    imgSrc: "/img/portfolio/p2.png",
    code: "https://github.com/jfudge/books-app",
    projectLink: "",
    tech: ["React", "JavaScript", "Express", "Nodejs", "Mongoose", "Mongodb", "HTML5/CSS"],
    description:
      "A web app for storing your favourite books. View your top books. Create and add new books to the list. Delete a book from the database when needed.",
  },
  {
    title: "TIFF REACT APP",
    imgSrc: "/img/portfolio/p3.png",
    code: "https://github.com/jfudge/react-tmdb-movies.git",
    projectLink: "https://movies-b2e50.firebaseapp.com/",
    tech: ["React", "CSS", "JavaScript"],
    description:
      "Developed with react. View the latest movies with interesting facts.",
  },
  {
    title: "FRIENDS APP",
    imgSrc: "/img/portfolio/p4.png",
    code: "https://github.com/jfudge/flask-friends-crud",
    projectLink: "",
    tech: ["PYTHON", "HTML", "CSS", "Flask", "MySQL"],
    description:
      "A friends app built with Python and Flask.",
  },
  {
    title: "Portfolio v2",
    imgSrc: "/img/portfolio/p6.jpg",
    code: "https://www.jamiefudge.com/",
    projectLink: "https://www.jamiefudge.com/",
    tech: ["React", "SCSS", "Typescript", "Tailwind CSS", "SCSS", "JavaScript"],
    description:
      "The is the second version of my Portfolio I made to showcase my projects. Built with React and Typescript.",
  },
  {
    title: "Portfolio v1",
    imgSrc: "/img/portfolio/p5.jpg",
    code: "https://v1.jamiefudge.com/",
    projectLink: "https://github.com/jfudge/portfolio-1.0",
    tech: ["HTML5", "CSS3", "JQuery", "Bootstrap"],
    description:
      "This is my first portfolio made from scratch with Bootstrap.",
  },
  {
    title: "Respsonsive Email Design",
    imgSrc: "/img/portfolio/p7.jpg",
    code: "https://github.com/jfudge/white-claw-email",
    projectLink: "https://em.jamiefudge.com/",
    tech: ["HTML", "CSS"],
    description:
      "Respsonsive email design built with HTML and CSS.",
  },
];