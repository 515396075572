//  icons
import {
  FiInstagram,
  FiGithub,
  FiTwitter,
  FiMail,
} from 'react-icons/fi';

import {
  BsTools
} from 'react-icons/bs';

import {
HiOutlineMailOpen
}
from "react-icons/hi";

import {
FaDraftingCompass,
FaLaptop
}
from "react-icons/fa";

// navigation
export const navigation = [
  {
    name: 'home',
    href: 'home',
  },
  {
    name: 'about',
    href: 'about',
  },
  {
    name: 'portfolio',
    href: 'portfolio',
  },
  {
    name: 'services',
    href: 'services',
  },
  {
    name: 'contact',
    href: 'contact',
  },
];

// social
export const social = [
  {
    icon: <FiMail />,
    href: 'mailto:jamiefudge13@gmail.com',
  },
  {
    icon: <FiInstagram />,
    href: 'https://www.instagram.com/J4mie_Fudge',
  },
  {
    icon: <FiGithub />,
    href: 'https://www.github.com/jfudge',
  },
  {
    icon: <FiTwitter  />,
    href: 'https://www.twitter.com/Jamie_Fudge',
  },
];

export const exploreServices = [
  {
    id: 'service-1',
    icon: <BsTools className="green s-head service-heading s-icon" />,
    title: 'WEB DEVELOPMENT',
    text: "It's important to tell a story that will engage your target audience to your product. I Make your website the best it can be."
  },
  {
    id: 'service-2',
    icon: <FaDraftingCompass className="green s-head service-heading s-icon" />,
    title: 'UI / UX DESIGN',
    text: "I'm passionate about designing and developing optimized user experiences that are fully accessible and easy to use."
  },
  {
    id: 'service-3',
    icon: <HiOutlineMailOpen className="green s-head service-heading s-icon" />,
    title: 'EMAIL DEVELOPMENT',
    text: "I create engageing emails that are easy to read and stand out for your client. Emails customers actually want to open."
  },
  {
    id: 'service-4',
    icon: <FaLaptop className="green s-head service-heading s-icon" />,
    title: 'APP DEVELOPMENT',
    text: "I have experience working with various javascript frameworks. I create modern scalable web applications using the MERN stack"
  }
];