import React from "react";
import { FiInstagram, FiGithub, FiTwitter, FiMail } from "react-icons/fi";
import { RiTwitterXFill } from "react-icons/ri";
import { motion } from "framer-motion";

const Socials: React.FC = () => {
  return (
    <ul className="flex space-x-6">
      <li className="flex items-center justify-center text-accent">
        <motion.a whileHover={{y:-2}} whileTap={{scale:0.9}} className="text-base" href="mailto:jamiefudge13@gmail.com">
          <motion.span
            initial={{ y: -30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.0 }}>
            <FiMail />
          </motion.span>
        </motion.a>
      </li>

      <li className="flex items-center justify-center text-accent">
      <motion.a whileHover={{y:-2}} whileTap={{scale:0.9}} className="text-base" href="https://www.instagram.com/j4mie_fudge/">
          <motion.span
            initial={{ y: -30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.1 }}>
            <FiInstagram />
          </motion.span>
        </motion.a>
      </li>

      <li className="flex items-center justify-center text-accent">
      <motion.a whileHover={{y:-2}} whileTap={{scale:0.9}} className="text-base" href="https://github.com/jfudge">
          <motion.span
            initial={{ y: -30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}>
            <FiGithub />
          </motion.span>
        </motion.a>
      </li>

      <li className="flex items-center justify-center text-accent">
      <motion.a whileHover={{y:-2}} whileTap={{scale:0.9}} className="text-base" href="https://twitter.com/Jamie_Fudge">
          <motion.span
            initial={{ y: -30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.3 }}>
            <RiTwitterXFill />
          </motion.span>
        </motion.a>
      </li>
    </ul>
  );
};

export default Socials;
